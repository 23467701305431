<template>
  <TermLayout />
</template>

<script>
import TermLayout from '@/views/terms/view/components/TermLayout'

export default {
  name: 'IdentityVerifyKcbTerms',
  components:{
    TermLayout
  },
}
</script>